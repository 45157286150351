import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CategoriesTabs from "../components/CategoriesTabs"

export default ({ collectionType }) => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/categories/" } }
      ) {
        edges {
          node {
            frontmatter {
              id
              nameAR
              nameEN
            }
          }
        }
      }
    }
  `)
  const list = allMarkdownRemark.edges.map(({ node }) => node.frontmatter)
  return <CategoriesTabs collectionType={collectionType} list={list} />
}
