import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import useLang from "../../hooks/useLang"

const StyledNav = styled.nav`
  position: relative;
  max-width: 90vw;
  margin: auto;

  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    list-style: none;
    a {
      white-space: nowrap;
      padding: 5px;
      margin: 5px;
      border-radius: 15px;
      display: inline-block;
      border: 1px solid #eee;
    }
  }
`

function Tab({ to, title }) {
  return (
    <li>
      <Link activeClassName="active" to={to}>
        {title}
      </Link>
    </li>
  )
}

function CategoriesTabs({ list, collectionType = "blog" }) {
  const language = useLang()
  const isAR = language === "ar"
  return (
    <StyledNav>
      <ListWrapper>
        <Tab
          to={isAR ? `/ar/${collectionType}/` : `/${collectionType}/`}
          title={isAR ? "عرض الكل" : "Show All"}
        />
        {list.map(({ id, nameAR, nameEN }) => (
          <Tab
            key={id}
            title={isAR ? nameAR : nameEN}
            to={`${isAR ? "/ar" : ""}/${collectionType}/categories/${id}`}
          />
        ))}
      </ListWrapper>
    </StyledNav>
  )
}

CategoriesTabs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default CategoriesTabs
