import React from "react"
import "./style.css"
import MainAreaSection from "../MainAreaSection"

const BlogMainArea = ({ title, description }) => (
  <MainAreaSection
    img={require("../../images/page-layer-bg4.jpg")}
    title={title}
    currentPage={title}
  >
    <div className="heading-text">
      <p>{description}</p>
    </div>
  </MainAreaSection>
)
export default BlogMainArea
